/* 
body {
    font: 112.5%/1.45em 'Montserrat',  sans-serif;
    box-sizing: border-box;
    overflow-y: scroll;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: hsla(0, 0%, 0%, 0.8);
    font-family: 'Montserrat',sans-serif, serif;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  } */

/* @media only screen and (max-width: 480px) {
    html {
      font-size: 100%;
    }
  }
   */

  .shopper-result-card {
    max-width: 90%;
    width: 800px;
    /* height: auto; */
    border: 1px solid #333;
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    padding: 1em;
    height: 650px;
  }
  .shopper-result-card-paused {
    max-width: 90%;
    width: 800px;
    /* height: auto; */
    border: 1px solid #333;
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    padding: 1em;
    height: 534px;
    background-color: "grey";
  }
  .shopper-result-card:hover {
    box-shadow: 3px 3px 5px 6px #ccc;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
  }
  .shopper-result-card img {
    width: 8rem;
    height: 8rem;
    margin: auto;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .center-text {
    text-align: center;
  }
  
  
  .shopper-result-card:first-of-type {
    margin-top: 3rem;
  }
  
  .row {
    display: flex;
  }
  
  .column {
    flex: 50%;
  }
  
  p {
    margin: 2px;
    font-size: 15px;
    }
  
  .container {
      display: flex;
      justify-content: center;
  }